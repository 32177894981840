<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="trial" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Group level 1</th>
                        <th>Group level 2</th>
                        <th>Group level 3</th>
                        <th>Group level 4</th>
                        <th>Product / Service Name</th>
                        <th>Unit</th>
                        <th>Selling Rate</th>
                        <th>Last Purchase Rate</th>
                        <th class="text-end">Opening (Qty)</th>
                        <th class="text-end">In (Qty)</th>
                        <th class="text-end">Out (Qty)</th>
                        <th class="text-end">Closing (Qty)</th>
                        <th class="text-end">Opening (Value)</th>
                        <th class="text-end">In (Value)</th>
                        <th class="text-end">Out (Value)</th>
                        <th class="text-end">Closing (Value)</th>
                        <th class="text-end">Sale (Value)</th>
                        <th class="text-end">Profit (Value)</th>
                    </tr>
                </thead>

                <tbody v-if="balances.length">
                    <tr v-for="(item, i) in balances" :key="i">
                        <td>{{ item.type }}</td>
                        <td>{{ item.group_level_1 }}</td>
                        <td>{{ item.group_level_2 }}</td>
                        <td>{{ item.group_level_3 }}</td>
                        <td>{{ item.group_level_4 }}</td>
                        <td>{{ item.product_name }}</td>
                        <td class="text-end">{{ item.unit }}</td>
                        <td class="text-end">{{ item.sell_rate }}</td>
                        <td class="text-end">{{ item.last_purchase_rate }}</td>
                        <td class="text-end">{{ item.opening_stock}}</td>
                        <td class="text-end">{{ item.in_quantity}}</td>
                        <td class="text-end">{{ item.out_quantity}}</td>
                        <td class="text-end">{{ item.closing_stock}}</td>
                        <td class="text-end">{{ item.opening_value}}</td>
                        <td class="text-end">{{ item.in_amount}}</td>
                        <td class="text-end">{{ item.out_amount}}</td>
                        <td class="text-end">{{ item.closing_value}}</td>
                        <td class="text-end">{{ item.total_sale_value}}</td>
                        <td class="text-end">{{ item.total_profit_value}}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="!balances.length" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StockRegisterTable',
    props: {
        acName: String,
        balances: {
            default: [],
            type: Array
        },
        reportTotalRow: {
            default: {},
            type: Object
        }
    }
}
</script>

<style scoped>
#trial td{
    padding: 2px
}
#trial th{
    padding: 10px 3px;
}
</style>
